<template>
  <div class="main-box">
    <el-form class="form-inline" :inline="true">
      <el-form-item label="">
        <el-input v-model="search.keywords" placeholder="请输入名称" size="small" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="handleSearch()">搜索</el-button>
      </el-form-item>
    </el-form>
    <div class="mg-bt-10">
      <el-button type="primary" size="small" icon="el-icon-plus" @click="addGroup()">添加</el-button>
      <el-button type="danger" size="small" icon="el-icon-delete" @click="delGroup()">删除</el-button>
    </div>
    <el-table
        :data="tableData"
        ref="rfTable"
        row-key="id"
        border
        style="width: 100%"
        size="mini"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
        @selection-change="handleSelectionChange">
      <el-table-column
          type="selection"
          align="center"
          width="55">
      </el-table-column>
      <el-table-column
          prop="title"
          label="供应商名称">
      </el-table-column>
      <el-table-column
          prop="img"
          label="图片"
          align="center"
          width="150">
        <template slot-scope="scope">
          <img :src="scope.row.img" width="60" height="60" v-if="scope.row.img" />
        </template>
      </el-table-column>
      <el-table-column
          prop="ordering"
          label="排序"
          align="center"
          width="100">
      </el-table-column>
      <el-table-column
          prop="create_time"
          label="添加时间"
          align="center"
          width="140">
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          align="center"
          width="150">
        <template slot-scope="scope">
          <div v-if="scope.row.pid>0">
            <el-button type="text" size="small" @click="editHourse(scope.row)">编辑仓库</el-button>
          </div>
          <div v-else>
            <el-button type="text" size="small" @click="addHourse(scope.row)">添加仓库</el-button>
            <el-button type="text" size="small" @click="editGroup(scope.row)">编辑</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        layout="total, prev, pager, next, jumper"
        :page-sizes="[10, 20, 30, 40]"
        :total="search.total">
    </el-pagination>
    <el-dialog
        title="编辑供应商"
        :visible.sync="dialogVisible"
        width="40%">
      <el-form :model="info" label-position="right" ref="ruleForm" label-width="80px" class="ruleForm" size="small">
        <el-form-item label="名称" prop="title">
          <el-input v-model="info.title" placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item label="图片" prop="title">
          <SelectImgs :selectNum="1" :selectData="[info.img]" :selectFunc="res => { info.img=res.pic }"></SelectImgs>
        </el-form-item>
        <el-form-item label="排序" prop="ordering">
          <el-input v-model="info.ordering" placeholder="请输入排序"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="saveSup" size="small">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
        title="编辑仓库"
        :visible.sync="dialogHourseVisible"
        width="40%">
      <el-form :model="hourse" label-position="right" ref="ruleForm" label-width="80px" class="ruleForm" size="small">
        <el-form-item label="名称" prop="title">
          <el-input v-model="hourse.title" placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="ordering">
          <el-input v-model="hourse.ordering" placeholder="请输入排序"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogHourseVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="saveHorse" size="small">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>

import SelectImgs from '../../components/SelectImgs'
export default {
  components: {
    SelectImgs
  },
  data() {
    return {
      dialogVisible: false,
      dialogHourseVisible: false,
      info: false,
      source_domain: this.config.SOURCE_DOMAIN,
      search: {
        current_page: 1,
        total: 0,
        limit: 10,
        keywords: ''
      },
      tableData: [],
      hourse: {
        id: 0,
        pid: 0,
        title: '',
        ordering: ''
      }
    };
  },
  created() {
    this.getList();
  },
  mounted() {
  },
  computed: {},
  methods: {
    addHourse(item) {
      this.hourse.id = 0
      this.hourse.pid = item.id
      this.hourse.title = ''
      this.hourse.ordering = ''
      this.dialogHourseVisible = true
    },
    editHourse(item) {
      this.hourse.id = item.id
      this.hourse.pid = item.pid
      this.hourse.title = item.title
      this.hourse.ordering = item.ordering
      this.dialogHourseVisible = true
    },
    getList() {
      var that = this
      that.showLoading()
      var param = {
        page: that.search.current_page,
        pageSize: that.search.limit,
        keywords: that.search.keywords
      }
      this.$api.merchant.supplierIndex(param, function (res) {
        that.hideLoading()
        if (res.errcode == 0) {
          that.tableData = res.data.data
          that.search.current_page = res.data.current_page
          that.search.total = res.data.total
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    addGroup() {
      this.info = {
        id: 0,
        title: '',
        ordering: ''
      }
      this.dialogVisible = true
    },
    editGroup(item) {
      this.info = JSON.parse(JSON.stringify(item))
      this.dialogVisible = true
    },
    saveSup() {
      if(this.info.title == '') {
        this.fail('请输入名称');
        return false
      }
      this.$api.merchant.supplierEdit(this.info, res => {
        this.dialogVisible = false
        if (res.errcode == 0) {
          this.success(res.errmsg)
          this.getList()
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    saveHorse() {
      if(this.hourse.title == '') {
        this.fail('请输入名称');
        return false
      }
      this.$api.merchant.supplierEdit(this.hourse, res => {
        this.dialogHourseVisible = false
        if (res.errcode == 0) {
          this.success(res.errmsg)
          this.getList()
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    delGroup() {
      var that = this
      var sel = this.$refs.rfTable.selection
      if (sel.length < 1) {
        that.tips('请选择删除项')
        return false
      }
      var ids = []
      sel.forEach(function (item) {
        ids.push(item.id)
      })
      this.$confirm('确认要删除这些数据吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.merchant.supplierDelete({ids: ids}, function (res) {
          if (res.errcode == 0) {
            that.success('删除成功')
            that.getList()
          } else {
            that.fail(res.errmsg)
          }
        })
      }).catch(() => {

      });
    },
    handleSearch() {
      this.search.current_page = 1
      this.getList()
    },
    handleSelectionChange(rows) {
    },
    handleCurrentChange(val) {
      this.search.current_page = val
      this.getList()
    },
    handleSizeChange(val) {
      this.search.current_page = 1
      this.search.limit = val
      this.getList()
    }
  }
};
</script>
<style scoped>
</style>
